import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import useTranscriptsEvents from "~/functions/useWatchlistTranscriptsEvents"; // import CiqTranscriptTable from "~/components/CiqTranscriptTable.vue"
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useBaseUtils from "~/functions/useBaseUtils";
import useWindow from "~/functions/useWindow";
export default defineComponent({
  // components: {
  //   CiqTranscriptTable,
  // },
  props: {
    tickers: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    wid: {
      type: String,
      "default": ""
    },
    hourThreshold: {
      type: Number,
      "default": 0.5
    }
  },
  setup: function setup(props) {
    var _useTranscriptsEvents = useTranscriptsEvents(props),
      error = _useTranscriptsEvents.error,
      loading = _useTranscriptsEvents.loading,
      cids = _useTranscriptsEvents.cids,
      fetchTranscripts = _useTranscriptsEvents.fetchTranscripts,
      footer = _useTranscriptsEvents.footer;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      userEpochToShortDate = _useDateTimeFormatter.userEpochToShortDate;
    var _useWindow = useWindow(props, "CiqTranscriptTable"),
      tableHeight = _useWindow.tableHeight;
    var formatDate = function formatDate(dateStr) {
      var dateObj = new Date(dateStr);
      return userEpochToShortDate.value.format(dateObj);
    };
    return {
      error: error,
      loading: loading,
      cids: cids,
      fetchTranscripts: fetchTranscripts,
      footer: footer,
      refCode: refCode,
      tableHeight: tableHeight,
      formatDate: formatDate
    };
  },
  data: function data() {
    return {
      headers: [{
        text: "Event",
        value: "headline",
        align: "center",
        "class": "eventName"
      }, {
        text: "Event Date",
        value: "mostimportantdateutc",
        align: "center"
      }]
    };
  },
  computed: {
    transcripts: function transcripts() {
      // this is a list of the watchlist sigDevs
      var wid = this.wid;
      var watchlistData = this.$store.state.watchlist.watchlistData[wid] || {
        transcripts: {
          list: []
        }
      };
      return watchlistData.transcripts;
    }
  },
  watch: {
    tickers: function tickers(newList) {
      if (newList.length > 0) {
        // refetch
        this.fetchTranscripts();
      }
    }
  },
  mounted: function mounted() {
    this.checkLastBeforeFetch();
  },
  methods: {
    checkLastBeforeFetch: function checkLastBeforeFetch() {
      if (Object.keys(this.cids).length > 0) {
        var lastFetch = this.transcripts.u;
        if (lastFetch) {
          var now = new Date();
          var hourDiff = (now - lastFetch) / 1000 / 60 / 60;
          if (hourDiff <= this.hourThreshold) {
            return;
          }
        }
        this.fetchTranscripts();
      }
    }
  }
});